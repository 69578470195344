<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <div class="pt-5 pb-2 pl-3">
          <v-img
              v-if="color == 'fd9a00'"
              src="@/assets/jet.jpeg"
             width="10em"
          />
          <v-img
              v-if="color == 'FF0000'"
              src="@/assets/edgars.png"
              width="15em"
          />
          <v-img
              v-if="color == '005CB0'"
              src="@/assets/logo.svg"
              width="15em"
          />
        </div>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',
    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      color:'',
      User_items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'dashboard',
          to: '/user/dashboard',
        },
        {
          icon: 'mdi-license',
          title: '3rd Party',
          to: '/user/third',
        },
        {
          icon: 'mdi-license',
          title: 'Zinara & Radio',
          to: '/user/licensing',
        },
        {
          icon: 'mdi-license',
          title: 'Combined',
          to: '/user/combined',
        },
      ],
      Manager_items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'dashboard',
          to: '/user/dashboard',
        },
        {
          icon: 'mdi-license',
          title: '3rd Party',
          to: '/user/third',
        },
        {
          icon: 'mdi-license',
          title: 'Zinara & Radio',
          to: '/user/licensing',
        },
        {
          icon: 'mdi-license',
          title: 'Combined',
          to: '/user/combined',
        },
        // {
        //   icon: 'mdi-account',
        //   title: 'Agency',
        //   to: '/branch/agency',
        // },
        {
          icon: 'mdi-account',
          title: 'Users',
          to: '/user/list',
        },
         {
          icon: 'mdi-account',
          title: 'Branches',
          to: '/branch/list',
        },
      ],
      FBCManager_items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'dashboard',
          to: '/user/dashboard',
        },
        {
          icon: 'mdi-license',
          title: '3rd Party',
          to: '/user/third',
        },
        {
          icon: 'mdi-license',
          title: 'Zinara & Radio',
          to: '/user/licensing',
        },
        {
          icon: 'mdi-license',
          title: 'Combined',
          to: '/user/combined',
        },
        {
          icon: 'mdi-account',
          title: 'Agency',
          to: '/branch/agency',
        },
        {
          icon: 'mdi-account',
          title: 'Users',
          to: '/user/list',
        }
      ],
      BSS_items: [
        {
          icon: 'mdi-account',
          title: 'Users',
          to: '/user/list',
        },
      ],
    }),
    computed: {
      ...mapState(['barColor', 'barImage', 'is_manager', 'is_bss', 'is_fbcmanager','approved_color','institution', 'is_agent']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        if (this.is_manager) {
          return this.Manager_items.map(this.mapItem)
        } else if (this.is_bss) {
          return this.BSS_items.map(this.mapItem)
        } else if (this.is_fbcmanager) {
          return this.FBCManager_items.map(this.mapItem)
        } else if (this.is_agent) {
          return this.User_items.map(this.mapItem)
        }
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },
    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
    created () {
      this.color = this.approved_color
      console.log(this.color)
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'
  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
